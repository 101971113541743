.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.title {
  margin-top: 10;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.hd-tag {
  position: absolute;
  right: 12px;
  /* top: 3px; */
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
}

/* .test {
  padding-top: 48.25%;
}

.test .video-js {
  height: 100% !important; 
  width: 100% !important;
} */

.title span {
  color: rgba(0, 0, 0, 0.8) !important;
}

.text-center {
  text-align: center;
}

.logo {
  width: 95px;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.tag-hd {
  position: absolute;
  font-size: 8px !important;
  font-weight: 900;
  color: #ffffff;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  /* font-family: var(--bs-font-sans-serif); */
  top: -5px;
  right: -4px;
}

@media only screen and (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1100px;
  }
}

.video-js .vjs-menu-button-inline.vjs-slider-active,
.video-js .vjs-menu-button-inline:focus,
.video-js .vjs-menu-button-inline:hover,
.video-js.vjs-no-flex .vjs-menu-button-inline {
  width: 5em !important
}

.video-js .vjs-controls-disabled .vjs-big-play-button {
  display: none !important
}

.video-js .vjs-control {
  /* width: 3em */
}

.video-js .vjs-menu-button-inline:before {
  width: 1.5em !important
}

.vjs-menu-button-inline .vjs-menu {
  left: 3em !important
}

/* .video-js.vjs-paused .vjs-big-play-button,
.vjs-paused.vjs-has-started.video-js .vjs-big-play-button {
  display: block !important
} */

/* .video-js .vjs-load-progress div,
.vjs-seeking .vjs-big-play-button,
.vjs-waiting .vjs-big-play-button {
  display: none !important
} */

.video-js .vjs-mouse-display:after,
.video-js .vjs-play-progress:after {
  padding: 0 .4em .3em !important
} 

.video-js.vjs-ended .vjs-loading-spinner {
  display: none !important
}

.video-js.vjs-ended .vjs-big-play-button {
  display: block !important
}

.video-js *,
.video-js:after,
.video-js:before {
  box-sizing: inherit !important;
  font-size: inherit !important;
  color: inherit !important;
  line-height: inherit !important
}

.video-js.vjs-fullscreen,
.video-js.vjs-fullscreen .vjs-tech {
  width: 100% !important;
  height: 100% !important
}

.video-js {
  font-size: 14px !important;
  overflow: hidden !important
}

/* .video-js .vjs-control {
  color: inherit !important
} */

.video-js .vjs-menu-button-inline:hover,
.video-js.vjs-no-flex .vjs-menu-button-inline {
  width: 8.35em !important
}

.video-js .vjs-volume-menu-button.vjs-volume-menu-button-horizontal:hover .vjs-menu .vjs-menu-content {
    height: 3em !important;
    width: 6.35em !important
}

/* .video-js .vjs-control:focus:before,
.video-js .vjs-control:hover:before {
  text-shadow: 0 0 1em #fff, 0 0 1em #fff, 0 0 1em #fff !important
  
} */

.vjs-icon-placeholder:hover:before {
  text-shadow: 0 0 1em #fff, 0 0 1em #fff, 0 0 1em #fff !important;
}

/* .video-js .vjs-play-control .vjs-icon-placeholder:hover:before,
.vjs-icon-volume-high:hover:before, .video-js .vjs-mute-control .vjs-icon-placeholder:hover:before {
  text-shadow: 0 0 1em #fff, 0 0 1em #fff, 0 0 1em #fff !important;
} */

/* .video-js .vjs-control:focus:before,
.video-js .vjs-control:hover:before,
.video-js .vjs-control:focus {
  text-shadow: 0em 0em 1em white !important; } */

.video-js .vjs-spacer,
.video-js .vjs-time-control {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-box-flex: 1 1 auto !important;
  -moz-box-flex: 1 1 auto !important;
  -webkit-flex: 1 1 auto !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important
}

.video-js .vjs-time-control {
  -webkit-box-flex: 0 1 auto !important;
  -moz-box-flex: 0 1 auto !important;
  -webkit-flex: 0 1 auto !important;
  -ms-flex: 0 1 auto !important;
  flex: 0 1 auto !important;
  width: auto !important;
  padding-left: 0em;
  padding-right: 0em;
  line-height: 24px !important;
}

.video-js .vjs-time-control.vjs-time-divider {
  width: 14px !important
}

.video-js .vjs-time-control.vjs-time-divider div {
  width: 100% !important;
  text-align: center !important
}

.video-js .vjs-time-control.vjs-current-time {
  margin-left: 1em !important
}

.video-js .vjs-time-control .vjs-current-time-display,
.video-js .vjs-time-control .vjs-duration-display {
  font-size: 1.1em !important;
  width: 100% !important
}

.video-js .vjs-time-control .vjs-current-time-display {
  text-align: right !important
}

.video-js .vjs-time-control .vjs-duration-display {
  text-align: left !important
}


.video-js .vjs-remaining-time,
.video-js.vjs-live .vjs-time-control.vjs-current-time,
.video-js.vjs-live .vjs-time-control.vjs-duration,
.video-js.vjs-live .vjs-time-control.vjs-time-divider,
.video-js.vjs-no-flex .vjs-time-control.vjs-remaining-time {
  display: none !important
}

.video-js.vjs-no-flex .vjs-time-control {
  display: table-cell !important;
  width: 4em !important
}

.video-js .vjs-progress-control {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  margin-left: 10px !important;
  width: 97.4% !important;
  height: .4em !important;
  top: -1.4em !important
}

.vjs-button > .vjs-icon-placeholder:before {
  font-size: 2.5em;
  line-height: 0.8;
}

.video-js .vjs-progress-control .vjs-load-progress,
.video-js .vjs-progress-control .vjs-play-progress,
.video-js .vjs-progress-control .vjs-progress-holder {
  height: 100% !important
}

.video-js .vjs-play-progress:before, .video-js .vjs-volume-level:before {
  display: none;
}

/* .video-js .vjs-play-progress:before {
  font-size: 8px;
  top: -1.9px;
}

.video-js .vjs-volume-level:before {
  font-size: 8px;
  top: -1.9px;
} */

.video-js .vjs-progress-control .vjs-progress-holder {
  margin: 0 !important
}

.video-js .vjs-progress-control:hover {
  height: 0.5em !important;
  top: -1.4em !important
}

.video-js .vjs-seek-to-live-control {
  height: 24px;
}

.vjs-volume-bar.vjs-slider-horizontal {
  height: 0.5em;
}

.vjs-slider-horizontal .vjs-volume-level {
  height: 0.5em;
}

.video-js .vjs-control-bar {
  -webkit-transition: -webkit-transform .1s ease 0s !important;
  -moz-transition: -moz-transform .1s ease 0s !important;
  -ms-transition: -ms-transform .1s ease 0s !important;
  -o-transition: -o-transform .1s ease 0s !important;
  transition: transform .1s ease 0s !important
}

.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-control-bar,
.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-control-bar,
.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-control-bar,
.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar,
.video-js.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar {
  visibility: visible !important;
  opacity: 1 !important;
  -webkit-backface-visibility: hidden !important;
  -webkit-transform: translateY(3.5em) !important;
  -moz-transform: translateY(3.5em) !important;
  -ms-transform: translateY(3.5em) !important;
  -o-transform: translateY(3.5em) !important;
  transform: translateY(3.5em) !important;
  -webkit-transition: -webkit-transform 1s ease 0s !important;
  -moz-transition: -moz-transform 1s ease 0s !important;
  -ms-transition: -ms-transform 1s ease 0s !important;
  -o-transition: -o-transform 1s ease 0s !important;
  transition: transform 1s ease 0s !important
}

.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-progress-control,
.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-progress-control,
.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-progress-control,
.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-progress-control,
.video-js.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-progress-control {
  height: .25em !important;
  top: -.25em !important;
  pointer-events: none !important;
  -webkit-transition: height 1s, top 1s !important;
  -moz-transition: height 1s, top 1s !important;
  -ms-transition: height 1s, top 1s !important;
  -o-transition: height 1s, top 1s !important;
  transition: height 1s, top 1s !important
}

.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active.vjs-fullscreen .vjs-progress-control,
.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive.vjs-fullscreen .vjs-progress-control,
.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active.vjs-fullscreen .vjs-progress-control,
.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive.vjs-fullscreen .vjs-progress-control,
.video-js.vjs-has-started.vjs-playing.vjs-user-inactive.vjs-fullscreen .vjs-progress-control {
  opacity: 0 !important;
  -webkit-transition: opacity 1s ease 1s !important;
  -moz-transition: opacity 1s ease 1s !important;
  -ms-transition: opacity 1s ease 1s !important;
  -o-transition: opacity 1s ease 1s !important;
  transition: opacity 1s ease 1s !important
}

.video-js.vjs-live .vjs-live-control {
  margin-left: 1em !important
}


.vjs-menu-button-popup .vjs-menu {
  bottom: 10px;
}

.video-js .vjs-volume-bar {
  margin: 0.7em 0.45em;
}

/* .video-js .vjs-big-play-button {
    top: 50% !important;
    left: 50% !important;
    margin-left: -1em!important;
    width: 2em !important;
    border: none;
    color: #fff;
    -webkit-transition: border-color .4s, outline .4s, background-color .4s !important;
    -moz-transition: border-color .4s, outline .4s, background-color .4s !important;
    -ms-transition: border-color .4s, outline .4s, background-color .4s !important;
    -o-transition: border-color .4s, outline .4s, background-color .4s !important;
    transition: border-color .4s, outline .4s, background-color .4s !important;
    background-color: rgba(0, 0, 0, .45) !important;
    font-size: 3.5em !important;
    height: 2em!important;
    line-height: 2em!important;
    margin-top: -1em!important
} */

/* .video-js .vjs-menu-button-popup .vjs-menu {
  left: -3em !important
} */

/* .video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
    background-color: transparent;
    width: 12em;
    left: -1.5em;
    padding-bottom: .5em
} */

/* .video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item,
.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-title {
    background-color: #151b17;
    margin: .3em 0;
    padding: .5em;
    border-radius: .3em
} */

/* .video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item.vjs-selected {
    background-color: #2483d5
} */

/* .video-js .vjs-big-play-button:active,
.video-js .vjs-big-play-button:focus,
.video-js:hover .vjs-big-play-button {
    background-color: rgba(36, 131, 213, .9)
} */

.video-js .vjs-loading-spinner {
  /* border-color: rgba(36, 131, 213, .8) !important */
}

.video-js .vjs-control-bar2 {
  background-color: #000 !important
}

.video-js .vjs-control-bar {
  background-color: transparent !important;
  color: #fff !important;
  font-size: 12px !important
}

.video-js .vjs-play-progress {
  /* background-color: #0199ff !important */
  background-color: #fff !important
}

.video-js .vjs-volume-level {
  background-color: #fff !important
}

.vjs-menu-title {
  display: none !important;
}

.vjs-seek-to-live-control.vjs-control.vjs-at-live-edge .vjs-icon-placeholder {
  color: red !important;
}



/* .video-js.vjs-live.vjs-liveui .vjs-seek-button, .video-js.vjs-live .vjs-seek-button {
  display: none !important;
} */


.navtop {
  /* padding-left: 20px; 
  padding-right: 20px; */
  border-bottom: 1px solid #c7c5c5;
  margin-bottom: 20px;
  /* box-shadow: 0 4px 12px 0 rgba(0,0,0,.07),0 2px 4px rgba(0,0,0,.05); */
}

@media only screen and (min-width: 1400px) {
  .container {
    max-width: 1024px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 960px;
  }
}